<div class="logo">
    <a href="/">
        <img src="assets/logo.png" alt="logo">
    </a>
</div>
<div class="back">
    <a href="/">Назад</a>
</div>
<div class="privacy">

    <h1 id="politics">Политика конфиденциальности!</h1>

    <p>1. Общие положения</p>
    <p>1.1. Настоящие Положение является официальным документом Администрации сайта, на котором оно размещено и определяет порядок обработки и защиты информации о физических лицах, пользующихся услугами интернет-сайта (далее - Сайт) и его сервисов (далее - Пользователи).</p>
    <p>1.2. Отношения, связанные со сбором, хранением, распространением и защитой информации о пользователях Сайта, регулируются настоящим Положением, иными официальными документами Администрации Сайта и действующим законодательством Российской Федерации. </p>
    <p>1.3. Регистрируясь, отправляя сообщения, заявки, лиды, иные послания с помощью средств и форм связи на Сайте, Пользователь выражает свое согласие с условиями Положения. В случае несогласия Пользователя с условиями Положения использование Сайта и его сервисов должно быть немедленно прекращено. Ответственность за это несет сам Пользователь. </p>
    <p>1.4. Администрация Сайта не проверяет достоверность получаемой (собираемой) информации о Пользователях, за исключением случаев, когда такая проверка необходима в целях исполнения Администрацией Сайта обязательств перед Пользователем. </p>

    <p>2. Условия и цели обработки персональных данных </p>
    <p>2.1. Администрация Сайта осуществляет обработку персональных данных пользователя в целях исполнения своих обязательств между Администрацией Сайта и Пользователем в рамках предоставления информации о деятельности и работе структурных подразделений владельцев Сайта. В силу статьи 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» отдельное согласие пользователя на обработку его персональных данных не требуется. В силу п.п. 2 п. 2 статьи 22 указанного закона Администрация Сайта вправе осуществлять обработку персональных данных без уведомления уполномоченного органа по защите прав субъектов персональных данных. </p>
    <p>2.2. Цели обработки персональных данных включают в себя: получение Пользователем информации, рассылок, документов и материалов, в том числе рекламного характера, обработка заказов Пользователя, направленных на получение товаров и услуг, консультационную поддержку Пользователя. </p>

    <p>3. Порядок ввода в действие и изменения Положения </p>
    <p>3.1. Положение вступает в силу с момента его размещения на Сайте и действует бессрочно, до замены его новым Положением. </p>
    <p>3.2. Действующая редакция Положения, являющимся публичным документом, доступна любому пользователю сети Интернет. </p>
    <p>3.3. Администрация Сайта вправе вносить изменения в Положение. При внесении изменений в Положение уведомляет об этом пользователей путем размещения новой редакции на Сайте по постоянному адресу. Предыдущие редакции Положения при этом утрачивают силу. </p>
    
    <p>4. Цели обработки информации </p>
    <p>4.1. Администрация Сайта осуществляет обработку информации о Пользователях, в том числе их персональных данных, в целях выполнения обязательств между Администрацией Сайта и Пользователем в рамках предоставления информации о деятельности и работе структурных подразделений владельцев Сайта. </p>
    
    <p>5. Состав персональных данных </p>
    <p>5.1. Персональные данные предоставляются Пользователем добровольно, означают согласие на их обработку Администрацией Сайта и включают в себя: </p>
    <p>5.1.1. предоставляемые Пользователями минимально необходимые данные для связи: имя (возможно использование вымышленного), номер мобильного телефона и/или адрес электронной почты. Иные данные (в том числе пол, возраст, дата рождения, адрес и т.д.) предоставляется Пользователем по желанию и в случае необходимости таких данных для связи с пользователем и осуществлением действий, связанных с предоставлением услуг или доставкой товаров Пользователю. </p>
    <p>5.2. Иная информация о Пользователях, обрабатываемая Администрацией Сайта. Администрация Сайта обрабатывает также иную информацию о Пользователях, которая включает в себя: </p>
    <p>5.2.1. стандартные данные, автоматически получаемые сервером при доступе к Сайту и последующих действиях Пользователя (IP-адрес хоста, вид операционной системы пользователя, страницы Сайта, посещаемые пользователем).</p>
    <p>5.2.2. информация, автоматически получаемая при доступе к Сайту с использованием закладок (cookies).</p>
    <p>5.2.3. информация, полученная в результате действий Пользователя на Сайте. </p>
    <p>5.2.4. информация, полученная в результате действий других пользователей на Сайте. </p>
    <p>5.2.5. информация, необходимая для идентификации Пользователя для доступа к сервисам сайта. </p>
    
    <p>6. Обработка информации о пользователях </p>
    <p>6.1. Обработка персональных данных осуществляется на основе следующих принципов: </p>
    <p>- законности целей и способов обработки персональных данных; </p>
    <p>- добросовестности; </p>
    <p>- соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Администрации Сайта; </p>
    <p>- соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных; </p>
    <p>6.2. Сбор персональных данных. </p>
    <p>6.2.1. Сбор персональных данных Пользователя осуществляется на Сайте при при внесении их пользователем по своей инициативе на момент обращения к Администрации сайта либо к сайту, согласно настроек Пользователя. </p>
    <p>6.2.2. Имя, адрес электронной почты и\или телефон предоставляются Пользователем для осуществления обратной связи и для стандартной работы на Сайте не требуются. </p>
    <p>6.2.3. Остальные Персональные данные, предоставляются Пользователем дополнительно по собственной инициативе с использованием соответствующих разделов и ресурсов Сайта. </p>
    <p>6.3. Хранение и использование персональных данных </p>
    <p>6.3.1. Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства. </p>
    <p>6.4. Передача персональных данных </p>
    <p>6.4.1. Персональные данные Пользователей не передаются каким-либо лицам, за исключением случаев, прямо предусмотренных настоящим Положением. </p>
    <p>6.4.2. Приложения, используемые Пользователями на Сайте, размещаются и поддерживаются третьими лицами (разработчиками), которые действуют независимо от Администрации Сайта и не выступают от имени или по поручению Администрации Сайта. Пользователи обязаны самостоятельно ознакомиться с правилами оказания услуг и политикой защиты персональных данных таких третьих лиц (разработчиков) до начала использования соответствующих приложений. </p>
    <p>6.4.3. Предоставление персональных данных Пользователей по запросу государственных органов (органов местного самоуправления) осуществляется в порядке, предусмотренном законодательством. </p>
    <p>6.5. Уничтожение персональных данных </p>
    <p>6.5.1. Персональные данные пользователя уничтожаются по письменной просьбе Пользователя. Просьба должна содержат идентификационные данные, которые прямо указывает на принадлежность информации данному Пользователю. </p>
    <p>7. Меры по защите информации о Пользователях. </p>
    <p>7.1. Администрация Сайта принимает технические и организационно-правовые меры в целях обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий. </p>
    <p>8. Ограничение действия Правил. </p>
    <p>8.1. Действие настоящих Правил не распространяется на действия и интернет-ресурсов третьих лиц. </p>
    <p>8.2. Администрация Сайта не несет ответственности за действия третьих лиц, получивших в результате использования Интернета или Услуг Сайта доступ к информации о Пользователе и за последствия использования информации, которая, в силу природы Сайта, доступна любому пользователю сети Интернет. </p>
    <p>8.3. Администрация Сайта рекомендует Пользователям ответственно подходить к решению вопроса об объеме информации о себе, передаваемой с Сайта.</p>
   
</div>
