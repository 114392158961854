<div class="logo">
    <a href="/">
        <img src="assets/logo.png" alt="logo">
    </a>
</div>

<div class="back">
    <a href="/">Назад</a>    
</div>

<div class="certificate">
    <!-- <h1>Сертификат</h1> -->
    <!-- <p>сертификат</p> -->
    <img src="assets/img/certificate/certificate1.jpg" alt="certificate1">
    <img src="assets/img/certificate/certificate2.jpg" alt="certificate2">
</div>