
<div class="header-background"></div>

<header class="header">
    <h1 class="services">Выполнение инженерно-геодезических работ</h1>
    <span class="ooo">ООО "Геометрикс"</span>
    <p class="foundation">Основана в 2012 году</p>
    <img class="logo" src="assets/l.png" alt="logo">
    <img class="logo-mini" src="assets/logo.png" alt="logo">

    <p class="email"><a href="mailto:geometrix61@mail.ru">geometrix61@mail.ru</a></p>
    <p class="phone">
        <img src="assets/img/social/phone.png" alt="phone"><a href="tel:+79081903754"> +79081903754</a><br>
        <img src="assets/img/social/phone.png" alt="phone"><a href="tel:+79286074999"> +79286074999</a>
    </p>
    
    <p class="whatsapp">
        <img src="assets/img/social/wa.png" alt="phone"><a href="https://wa.me/79044478596"> +79044478596</a>
    </p>
</header>

<div class="hr"></div>

<!-- ------------------------------ -->

<main class="main">

<div class="top">
    <!-- <h1>top</h1> -->
    <img src="assets/top++.jpg" alt="header">
    <h3>Получите комплексный отчёт</h3>
    <h1>О ГЕОДЕЗИЧЕСКИХ ИЗЫСКАНИЯХ</h1>
</div>
<div class="promo">
    <h1>promo</h1>
</div>


<div class="consultation">
    <form (ngSubmit)="onClickForm()" [formGroup]="form" class="form">
    <!-- method="GET" action="/api" class="form" id="form"> -->
        <h1>Заполните форму</h1>
        <h4>чтобы получить бесплатную<br>
            консультацию специалиста</h4>
        <!-- <input type="hidden" name="login" id="login" value="test" /> -->
        <!-- <input type="text" placeholder="Ваше имя*" name="name" id="name" maxlength="100" />
        <input type="text" placeholder="Ваш телефон*" name="phone" id="phone" maxlength="100" />
        <input type="text" placeholder="Ваш Email" name="email" id="email" maxlength="100" /> -->

        <input type="text" placeholder="Ваше имя*" formControlName="name" required maxlength="20">
        <input type="text" placeholder="Ваш телефон*" formControlName="phone" required maxlength="20">
        <input type="text" placeholder="Ваш Email" formControlName="email" maxlength="40">

        <button 
            class="btn" 
            type="submit"
            id="submit"
            [disabled]="form.invalid"
        >Получить консультацию</button>

        <!-- (click)="onClickForm()" class="btn" type="submit" id="submit">Получить консультацию</button> -->
    </form>
    <img class="instrument" src="assets/instrument.png" alt="instrument">

    <img class="image2" src="assets/img/equipment/Image4.png" alt="image">

</div>


<div (click)="delete_toast()" class="toast" id="toast">
    <div class="block">
        <h1>Сообщение отправленно</h1>
        <button (click)="delete_toast()">Ясно</button>
    </div>
</div>



<div class="why-are-we">
    <!-- <h1>why-are-we</h1> -->
    
    <h1>О компании</h1> 
    <div>
        <p>&nbsp;&nbsp;&nbsp;ООО «Геометрикс» — топографо-геодезическая компания, основанная в 2012 г. группой выпускников факультета “Прикладная геодезия” Ростовского государственного строительного университета.</p>
        <p>&nbsp;&nbsp;&nbsp;Мы выполняем все виды геодезических работ на территории г. Ростова-на-Дону и Ростовской области, а также других регионов Российской Федерации.</p>
        <p>&nbsp;&nbsp;&nbsp;Производственная деятельность компании, в первую очередь, связана с выполнением инженерно-геодезических изысканий под строительство.</p>
        <p>&nbsp;&nbsp;&nbsp;Материально-техническая база организации и квалификация сотрудников позволяет при выполнении геодезических изысканий строго соблюдать требования законодательства Российской Федерации, нормативных технических документов.</p>
        <p>&nbsp;&nbsp;&nbsp;Использование современных приборов и технологий позволяет нашей фирме оперативно выполнять работы по любому заказу.</p>
        <p>&nbsp;&nbsp;&nbsp;В число наших заказчиков входят строительные и проектные компании, промышленные предприятия, муниципальные и коммерческие организации, а также частные лица.</p>
    </div>

    <img class="image1" src="assets/img/equipment/Image1.png" alt="Image1">

</div>



<div class="about-photo">
    <h1>Мы предлагаем:</h1>
    <div class="container">
        <div class="card card1" id="card1">
            <div class="face face1">
                <div class="content">
                    <img src="assets/about-photo-one.jpg" alt="one">
                    <h3>Топографическая съемка</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>Топографическая съемка — наиболее востребованный вид геодезических работ. Потребность в ней возникает при обновлении топокарт, изысканиях, составлении генеральных планов и рабочих чертежей, а также при создании проектов ландшафтного дизайна и решении вопросов вертикальной планировки.</p>	
                </div>
            </div>
        </div>
        <div class="card card2" id="card2">
            <div class="face face1">
                <div class="content">
                    <img src="assets/about-photo-two.jpg" alt="two">
                    <h3>Сопровождение строительства</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>Геодезическое сопровождение строительства подразумевает под собой комплекс мероприятий по разбивке и контролю монтажа конструктивных элементов  на определенном строительном объекте. Наши сотрудники максимально полно и достоверно отобразят всю текущую ситуацию на строительном объекте.</p>	
                </div>
            </div>
        </div>
        <div class="card card3" id="card3">
            <div class="face face1">
                <div class="content">
                    <img src="assets/about-photo-three.jpg" alt="three">
                    <h3>Наблюдение за осадками</h3>
                </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>Наблюдения за деформациями или геодезический мониторинг зданий и сооружений  подразумевает своевременное отслеживание опасных величин деформаций и оползневых процессов, выявление причин их появления и прогнозирование дальнейшего развития процессов.</p>	
                </div>
            </div>
        </div>
    </div>

    <div (click)="arrowLeft()" class="arrow-left">
        <img src="assets/img/buttons/arrow-left.png" alt="arrow-left">
    </div>
    <div (click)="arrowRight()" class="arrow-right">
        <img src="assets/img/buttons/arrow-right.png" alt="arrow-right">
    </div>

    <img class="image3" src="assets/img/equipment/Image3.png" alt="Image3">

</div>


<div class="reason">
    <!-- <h1>Почему именно мы?</h1> -->
    
    <h1>Почему Вы должны выбрать именно нас:</h1>

    <div class="box">
        <div class="reason-one">
            <h3>У нас</h3>
            <p>- Большой опыт работы в данной сфере</p>
            <p>- Достойная репутация</p>
            <p>- Индивидуальный подход к каждому клиенту</p>
        </div>
        <div class="reason-two">
            <h3>Потому что мы</h3>
            <p>- Cтрого придерживаемся установленных сроков исполнения</p>
            <p>- Используем только современное геодезическое оборудование</p>
        </div>
        <div class="reason-three">
            <h3>У нас</h3>
            <p>- Доступные, честные цены</p>
            <p>- К работе допускаются только сотрудники имеющие соответствующую подготовку</p>
            <p>- Постоянный контроль качества на всех этапах работы</p>
        </div>
    </div>

    <div class="reason-img-one">
        <img src="assets/reason-img-one.jpg" alt="reason-img-one">
    </div>
    <div class="reason-img-two">
        <img src="assets/reason-img-two.jpg" alt="reason-img-two">
    </div>
    <div class="reason-img-three">
        <img src="assets/reason-img-three.jpg" alt="reason-img-three">
    </div>
</div>

<div class="about-text">

    <div class="box">
        <h1>Виды работ:</h1>
        <div id="text1" class="text">
            <h3>Вынос границ земельного участка</h3>
            <p>Вынос границ земельного участка в натуру — это процедура, в ходе которой на конкретном земельном наделе устанавливаются межевые знаки для обозначения его границ. Она является разновидностью геодезических работ, в результате проведения которых на участке устанавливаются и закрепляются межевые знаки. Происходит это в соответствии с документами и данными, которые содержатся в Едином государственном  реестре недвижимости.</p>
        </div>

        <div id="text2" class="text">
            <h3>Подсчет объемов земляных масс</h3>
            <p>Данный вид геодезических работ выполняется для определения фактического объема сыпучего строительного материала(песок, щебень, грунт и т.д.), который находится на площадке складирования, а так же для определения объемов разработанного или отсыпанного грунта механизированным способом.</p>
        </div>
        <div id="text3" class="text">
            <h3>Гидрографические работы</h3>
            <p>Инженерно – гидрографические работы - это комплекс изыскательских работ, позволяющих получить данные о ситуации, рельефе и водной поверхности для составления топографических планов и профилей водных объектов (русел рек, акваторий водохранилищ, озер, прибрежной части морей и прилегающей к ним части берега, со всеми их характерными особенностями).</p>
        </div>
        <div id="text4" class="text">
            <h3>Аэрофотосъёмка</h3>
            <p>Основная область применения аэрофотосъёмки - это топография и геодезические работы. С помощью БПЛА проводят аэрофотосъемку земельных ресурсов, карьеров и различных строений. Для геодезической аэрофотосъемки используются высокоточные летательные аппараты, оснащенные специальной аппаратурой фото и видео фиксации. Наша компания имеет собственный парк профессиональных летательных аппаратов позволяющих с высокой точностью выполнять данные задачи.</p>
        </div>

        <!-- <div id="text1">
            <h3>Межевание</h3>
            <p>Комплекс мероприятий, который обеспечит быстрое, квалифицированное межевание земельного участка: уточнение границ, раздел, перераспределение, объединение, образование земельных участков. Опыт и профессиональные навыки наших специалистов гарантируют точность всех показателей.</p>
        </div> -->
        <!-- <div id="text2">
            <h3>Топоплан</h3>
            <p>Топографическая съемка местности – комплекс мероприятий в составе инженерных изысканий, направленный на создание планов местности или топографических карт для дальнейшего их использования строителями, военными, туристами и т.д. Выполнение топографической съемки, как правило, осуществляется на месте и результатом этого вида деятельности является топоплан определенного масштаба.</p>
        </div> -->
        <!-- <div id="text3">
            <h3>Сопровождение</h3>
            <p>Геодезическое сопровождение строительства подразумевает под собой комплекс мероприятий по разбивке и контролю монтажа конструктивных элементов  на определенном строительном объекте. «Геометрикс» предлагает вам комплексный подход к ее созданию. Все заботы о геодезии мы возьмем на себя!</p>
        </div> -->
    </div>

    <div (click)="arrowLeftTwo()" class="arrow-left">
        <img src="assets/img/buttons/arrow-left.png" alt="arrow-left">
    </div>
    <div (click)="arrowRightTwo()" class="arrow-right">
        <img src="assets/img/buttons/arrow-right.png" alt="arrow-right">
    </div>

    <img class="image4" src="assets/img/equipment/Image2.jpg" alt="image">

</div>


<div class="scheme">
    <h1>scheme</h1>
</div>

<div class="reviews">
    <h1>reviews</h1>
</div>

<div class="map">
    <!-- <h1>map</h1> -->
    <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2277.71671512554!2d39.723912792176!3d47.24246509347906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40e3b9b3ff95edd7%3A0x3df435d2d9858d18!2z0YPQuy4g0J3QsNC90YHQtdC90LAsIDE0ONCQLCDQoNC-0YHRgtC-0LIt0L3QsC3QlNC-0L3Rgywg0KDQvtGB0YLQvtCy0YHQutCw0Y8g0L7QsdC7LiwgMzQ0MDM4!5e0!3m2!1sru!2sru!4v1609084672156!5m2!1sru!2sru" 
        frameborder="0" 
        style="border:0;" 
        allowfullscreen="" 
        aria-hidden="false" 
        tabindex="0">
    </iframe>
    
</div>

</main>

<!-- ------------------------------ -->

<footer class="footer">
<!-- <div class="box"> -->
    <!-- <h1>footer</h1> -->
    <div class="director">
        <!-- <p>Директор:</p>
        <h4>Кочкин Алексей Евгеньевич</h4>
        <br> -->
        <h4>OOO "Геометрикс"</h4>
        <p>Юридический адрес:</p>
        <p>&nbsp;&nbsp;&nbsp;344010, Россия, г.Ростов-на-Дону, </p>
        <p>&nbsp;&nbsp;&nbsp;ул.Нансена, д.148а, офис 109</p>
        <p>ОГРН: 1126194004100</p>
        <p>ИНН: 6168059100</p>
    </div>
    <div class="social">
        <label>Наши соц.сети:</label>
        <br>
        <a href="https://www.instagram.com/geometrix61/" id="insta">Инстаграм</a>
        <br>
        <a href="https://vk.com/geometrix61" id="vk">ВКонтакте</a>
    </div>
    <div class="certificate">
        <p>Право ООО "Геометрикс" осуществлять на территории РФ инженерно-геодезические работы подтверждается <a href="https://prospectors-sroufo.ru/reestr/registry/entry?number=230">свидетельством № 0230.04-2012-6168059100-И-020</a> от «09» февраля 2015 г., выданном СРО НП «Объединение Изыскателей Южного и Северо-Кавказского округов».</p>
    </div>
    <div class="privacy">
        <a href="privacy">Политика конфиденциальности</a>
        <!-- <a routerLink="privacy" queryParams="d" fragment="politics" routerLinkActive="activebutton">Политика конфиденциальности</a> -->                
    </div>
    <div class="hutoryanin" (click)="onClickDivHutoryanin()">
        <h4>Сайт разработал <a href="https://hutoryanin.ru">ХуторянинЪ</a></h4>
    </div>
<!-- </div> -->
</footer>
